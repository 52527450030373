import {
  Button,
  IconButton,
  Snackbar,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import { makeStyles } from "@mui/styles";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Flex } from "../components/Flex";
import { SignIn } from "../components/SignIn";
import { useUser } from "../redux/selectors";
import { ArrowBack, Share, IosShare } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import logoBlack from "../assets/TheGifterTextLogo.png";
import logoWhite from "../assets/TheGifterTextLogoWhite.png";
import { UserAccount } from "../components/modals/UserAccount";
import { storefcmtoken } from "../api/ChristmasListApi";
import { useRouteMatch } from "react-router";
import { useDispatcher } from "../utils/fetchers";
import { getAllWishListsAction } from "../redux/slices/wishLists";
import _ from "lodash";
import { isIPhone } from "../constants/device";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    background: theme.palette.mode === "light" ? blue[100] : blue[900],
  },
  header: {
    fontWeight: 600,
    padding: "8px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: isIPhone ? 48 : 0,
  },
  img: {
    height: 40,
  },
}));

export const initializeNotifications = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") return;

    const messaging = getMessaging();
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY ?? "",
    });

    await storefcmtoken({ token });
  } catch (e) {
    console.error(`Notifications not supported: ${e}`);
  }
};

const InitializeNotificationToken = () => {
  const user = useUser();
  const [message, setMessage] = useState<MessagePayload | null>(null);
  const match = useRouteMatch<{ exchangeEvent: string }>(
    "/gift-exchange-event/:exchangeEvent"
  );
  const exchangeEventUrlParam = match?.params?.exchangeEvent ?? "";
  const fetchAllWishLists = useDispatcher(getAllWishListsAction);

  const action = useMemo(() => {
    if (exchangeEventUrlParam === message?.data?.eventId) {
      return (
        <Button
          onClick={() => {
            fetchAllWishLists({ exchangeEvent: exchangeEventUrlParam });
            setMessage(null);
          }}
        >
          Refresh
        </Button>
      );
    }
    return (
      <a href={`/gift-exchange-event/${message?.data?.eventId}`}>
        <Button>Go To</Button>
      </a>
    );
  }, [exchangeEventUrlParam, fetchAllWishLists, message]);

  useEffect(() => {
    const token = localStorage.getItem("notificationToken");
    if (!token) {
      initializeNotifications();
    }
    let unsubscribe = _.noop;
    try {
      const messaging = getMessaging();
      unsubscribe = onMessage(messaging, (payload) => {
        if (user && payload.data?.intendedUser === user.uid)
          setMessage(payload ?? null);
      });
    } catch (e) {
      console.error(`onMessage not supported: ${e}`);
    }
    return () => unsubscribe();
  }, [user]);
  return (
    <Snackbar
      open={!!message}
      onClose={() => setMessage(null)}
      autoHideDuration={10000}
      message={
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="column">
            <Typography variant="subtitle1">
              {message?.notification?.title}
            </Typography>
            <Typography variant="subtitle2">
              {message?.notification?.body}
            </Typography>
          </Flex>
          {action}
        </Flex>
      }
    />
  );
};

const PageWrapper = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const classes = useStyles();
  const user = useUser();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sentVerifyEmail, setSentVerifyEmail] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sentVerifyEmail) {
      timeout = setTimeout(() => setSentVerifyEmail(false), 10000);
    }
    return () => clearTimeout(timeout);
  }, [sentVerifyEmail]);
  // Get whether we're at the home page or not
  const isHome = window.location.pathname === "/";

  return (
    <div className={classes.root}>
      <Flex className={classes.header}>
        {isHome ? (
          <div />
        ) : (
          <a href="/">
            <IconButton>
              <ArrowBack />
            </IconButton>
          </a>
        )}
        <img
          src={theme.palette.mode === "light" ? logoBlack : logoWhite}
          alt="Gifter Logo"
          className={classes.img}
        />
        <Flex columnGap="8px">
          <UserAccount />
          <IconButton
            onClick={() => {
              setSnackbarOpen(true);
              // Copy the url to the clipboard
              navigator.clipboard.writeText(window.location.href);
            }}
          >
            {isIPhone ? <IosShare /> : <Share />}
          </IconButton>
        </Flex>
      </Flex>

      {!user ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <Typography>Sign In to see your stuff!</Typography>
          <SignIn />
        </Flex>
      ) : !user.emailVerified ? (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          padding="32px"
        >
          <Typography>
            {`Please verify your email ${user.email} to continue using The Gifter app!`}
          </Typography>
          <Button
            onClick={async () => {
              setSentVerifyEmail(true);
              await user.sendEmailVerification({ url: window.location.href });
            }}
            variant="contained"
            disabled={sentVerifyEmail}
          >
            {sentVerifyEmail ? "Sent verification email!" : "Verify Email"}
          </Button>
        </Flex>
      ) : (
        <>
          <InitializeNotificationToken />
          {children}
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="Copied link to clipboard!"
        autoHideDuration={2000}
      />
    </div>
  );
};

export default PageWrapper;
