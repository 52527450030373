import { checkHealth } from "../api/ChristmasListApi";
import { useUser } from "../redux/selectors";
import { anyIsIdle, useReduxState } from "../utils/fetchers";
import { useEffect, useMemo, useState } from "react";
import { Flex } from "../components/Flex";
import _ from "lodash";
import { FetchedComponent } from "../components/fetchers/FetchedComponent";
import { Button, Card, Theme, Typography } from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { AddBox } from "@mui/icons-material";
import {
  emptyExchangeEvent,
  getAllExchangeEventsAction,
} from "../redux/slices/exchangeEvent";
import classNames from "classnames";
import { useQuery } from "../utils/routing";
import { DocTitle } from "../utils/useDocTitleEffect";
import { useVerticalWrapLayout } from "../utils/useVeritcalWrapLayout";
import {
  CARDS_CONTAINER_PADDING,
  CARD_GAP,
  CARD_WIDTH,
} from "../constants/style";
import { ExchangeEventCard } from "../components/ExchangeEventCard";
import { User } from "../models/functions";

const CREATE_CARD_KEY = `create-card-${Date.now()}`;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: `min(calc(100vw - 8px), ${CARD_WIDTH}px)`,
  },
  createCard: {
    height: 300,
  },
}));

export const ExchangeEventListPage = () => {
  const classes = useStyles();
  const user = useUser();
  const queryParams = useQuery();
  const [exchangeEventsResource, fetchExchangeEvents] = useReduxState(
    "exchangeEvent",
    getAllExchangeEventsAction
  );
  const [creatingEvent, setCreatingEvent] = useState(false);

  // Fetch exchange events.
  useEffect(() => {
    if (user?.uid && anyIsIdle(exchangeEventsResource)) {
      fetchExchangeEvents({ uid: user.uid });
    }
  }, [exchangeEventsResource, fetchExchangeEvents, user]);

  const cardKeys = useMemo(
    () => [..._.keys(exchangeEventsResource.data), CREATE_CARD_KEY],
    [exchangeEventsResource.data]
  );

  const { cardRefs, stacks } = useVerticalWrapLayout({
    width: CARD_WIDTH,
    gap: CARD_GAP,
    gutters: CARDS_CONTAINER_PADDING,
    keys: cardKeys,
    sortStackBy: (key) => (key === CREATE_CARD_KEY ? 1 : 0),
  });

  return (
    <Flex overflow="auto" pb="32px">
      <DocTitle title="Gifter" />
      {!!user ? (
        <FetchedComponent resource={exchangeEventsResource}>
          {(exchangeEventsMap) => {
            const userOptions = _.uniq(
              _.flatMap(exchangeEventsMap, (event) => event.data.users)
            );
            return (
              <Flex
                gap={`${CARD_GAP}px`}
                justifyContent="center"
                alignItems="flex-start"
                flexGrow={1}
              >
                {_.map(stacks, (columnOfKeys) => {
                  return (
                    <Flex
                      gap={`${CARD_GAP}px`}
                      flexDirection="column"
                      py="16px"
                      key={columnOfKeys.join(",")}
                    >
                      {_.map(columnOfKeys, (exchangeEventId) => {
                        const exchangeEventResource =
                          exchangeEventsMap[exchangeEventId];
                        return exchangeEventId === CREATE_CARD_KEY ? (
                          creatingEvent ? (
                            <ExchangeEventCard
                              exchangeEvent={{
                                ..._.cloneDeep(emptyExchangeEvent),
                                date: moment().add(1, "w").toDate().getTime(),
                                updatedAt: Date.now(),
                                author: _.pick(user, [
                                  "uid",
                                  "email",
                                  "displayName",
                                ]) as User,
                              }}
                              userOptions={userOptions}
                              initialEditMode={true}
                              onCancel={() => setCreatingEvent(false)}
                              onSave={() => setCreatingEvent(false)}
                              key={CREATE_CARD_KEY}
                              ref={cardRefs[CREATE_CARD_KEY]}
                            />
                          ) : (
                            <Card
                              className={classNames(
                                classes.card,
                                classes.createCard
                              )}
                              elevation={3}
                              key={CREATE_CARD_KEY}
                              ref={cardRefs[CREATE_CARD_KEY]}
                            >
                              <Flex
                                gap="16px"
                                height="100%"
                                alignItems="stretch"
                              >
                                <Button
                                  onClick={() => {
                                    setCreatingEvent(true);
                                  }}
                                  fullWidth
                                >
                                  <Flex
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    flexGrow={1}
                                  >
                                    <AddBox />
                                    <Typography variant="h5">
                                      Create new event
                                    </Typography>
                                  </Flex>
                                </Button>
                              </Flex>
                            </Card>
                          )
                        ) : (
                          <FetchedComponent
                            resource={exchangeEventResource}
                            key={exchangeEventId}
                          >
                            {(exchangeEvent) => (
                              <ExchangeEventCard
                                exchangeEvent={exchangeEvent}
                                userOptions={userOptions}
                                key={exchangeEventId}
                                ref={cardRefs[exchangeEventId]}
                              />
                            )}
                          </FetchedComponent>
                        );
                      })}
                    </Flex>
                  );
                })}
              </Flex>
            );
          }}
        </FetchedComponent>
      ) : null}
      {queryParams.get("debug") && (
        <Button
          variant="contained"
          onClick={async () => {
            let result: any = null;
            try {
              result = await checkHealth();
              console.log(`Health: ${result}`);
            } catch (e) {
              console.error(`Health error: ${e}`);
            }
            return result;
          }}
        >
          Health
        </Button>
      )}
    </Flex>
  );
};
