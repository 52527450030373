import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { State } from "./rootReducer";
import { ExchangeEvent } from "../models/functions";
import { FetchedResource } from "../utils/fetchers";
import { useParams } from "react-router-dom";
import _ from "lodash";

export const useUser = () => useSelector((state: State) => state.user.data);

export const useExchangeEvents = () =>
  useSelector((state: State) => state.exchangeEvent);

export const useExchangeEvent = (exchangeEventId: string) => {
  return useSelector((state: State) => {
    return state.exchangeEvent.data[exchangeEventId] as
      | FetchedResource<ExchangeEvent>
      | undefined;
  });
};

export const useCurrentExchangeEvent = () => {
  const { exchangeEvent: exchangeEventUrlParam } = useParams<{
    exchangeEvent: string;
  }>();
  return useExchangeEvent(exchangeEventUrlParam);
};

export const useWishLists = () =>
  useSelector((state: State) => {
    return state.wishLists;
  });

export const useGetUserShortName = () => {
  const shortNameMap = useSelector((state: State) => state.userShortNames);
  return useCallback((uid: string) => shortNameMap[uid], [shortNameMap]);
};

export const useGetWishListNameFromEmail = () => {
  const wishLists = useWishLists();
  const emailMap = useMemo(() => {
    return _.reduce(
      wishLists.data,
      (acc, wishList) => {
        acc[wishList.author.email] = wishList.title;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [wishLists.data]);
  return useCallback((email: string) => emailMap[email] ?? email, [emailMap]);
};
