import { Close } from "@mui/icons-material";
import { Chip, Dialog, IconButton, Typography } from "@mui/material";
import _ from "lodash";
import { useContext, useMemo } from "react";
import { IdeaMarkStatus } from "../../models/functions";
import {
  useCurrentExchangeEvent,
  useGetWishListNameFromEmail,
  useUser,
  useWishLists,
} from "../../redux/selectors";
import { Flex } from "../Flex";
import { ModalContext, ModalType } from "./ModalContext";

export const MyClaimsModal = () => {
  const { modal, setModal } = useContext(ModalContext);
  const exchangeEvent = useCurrentExchangeEvent();
  const user = useUser();
  const wishLists = useWishLists();
  const emailToShortName = useGetWishListNameFromEmail();
  const claims = useMemo(() => {
    return _.orderBy(
      _.map(
        _.filter(
          wishLists.data,
          (list) => list.author.uid !== user?.uid || list.isExtra
        ),
        (list, id) => {
          return {
            recipient: list.title,
            gifts: _.filter(
              list.ideas,
              (idea) =>
                idea.mark?.author.uid === user?.uid &&
                idea.mark?.status === IdeaMarkStatus.Completed
            ),
          };
        }
      ),
      ({ gifts }) => gifts.length,
      "desc"
    ).filter(({ gifts }) => gifts.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid, wishLists.data]);
  const gifterIndex =
    exchangeEvent?.data.drawNames.gifters.findIndex(({ emails }) =>
      emails.includes(user?.email || "")
    ) ?? -1;
  return (
    <Dialog open={modal === ModalType.MyClaims} onClose={() => setModal(null)}>
      <Flex flexDirection="column" p="32px" minWidth="300px" gap="32px">
        <Flex justifyContent="space-between" alignItems="center">
          <Typography variant="h4">My Gifts</Typography>
          <IconButton onClick={() => setModal(null)}>
            <Close />
          </IconButton>
        </Flex>
        {exchangeEvent && gifterIndex !== -1 && (
          <div>
            <Typography>You're matched with:</Typography>
            {exchangeEvent.data.drawNames.gifters[
              exchangeEvent.data.drawNames.matches[gifterIndex]
            ].emails.map((email) => (
              <Chip
                key={email}
                label={emailToShortName(email)}
                color="primary"
              />
            ))}
          </div>
        )}
        <div>
          <Typography>
            {claims.length
              ? "You've claimed gifts for:"
              : "You haven't claimed any gifts yet"}
          </Typography>
          {claims.map(({ recipient, gifts }, idx) => {
            return (
              <Flex flexDirection="column" key={idx}>
                <Typography>{`${recipient} (${gifts.length})`}</Typography>
                <Flex gap="8px" flexWrap="wrap">
                  {gifts.map((gift) => {
                    return <Chip key={gift.id} label={gift.title} />;
                  })}
                </Flex>
              </Flex>
            );
          })}
        </div>
      </Flex>
    </Dialog>
  );
};
