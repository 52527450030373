import firebase from "firebase/compat/app";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useFetchedResource } from "../../utils/fetchers";
import { FetchedComponent } from "../fetchers/FetchedComponent";

const useStyles = makeStyles((theme: Theme) => ({
  forgotPassword: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  dialogContent: {
    "&&": {
      paddingTop: 16,
    },
  },
}));

type SuccessProps = {
  onClose: () => void;
};
const Success = ({ onClose }: SuccessProps) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 5000);
    return () => clearTimeout(timeout);
  }, [onClose]);
  return (
    <Typography>
      We have sent you an email with a link to reset your password.
    </Typography>
  );
};

const sendPasswordResetEmail = async (email: string) => {
  const result = await firebase.auth().sendPasswordResetEmail(email, {
    url: `https://thegifter.app/?email=${email}`,
    iOS: {
      bundleId: "app.thegifter",
    },
    handleCodeInApp: true,
  });
  console.log(result);
  return result;
};

type ModalProps = {
  email: string;
  setEmail: (email: string) => void;
  setOpen: (open: boolean) => void;
};
const Modal = ({ email, setEmail, setOpen }: ModalProps) => {
  const classes = useStyles();
  const [response, submitSendPasswordResetEmail] = useFetchedResource(
    sendPasswordResetEmail
  );
  return (
    <>
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
          id="email"
          label="Email Address"
          type="email"
          fullWidth
        />
        <Button
          onClick={async () => {
            await submitSendPasswordResetEmail(email);
          }}
        >
          Send reset password email
        </Button>
        <FetchedComponent resource={response}>
          {(data) => <Success onClose={() => setOpen(false)} />}
        </FetchedComponent>
      </DialogContent>
    </>
  );
};

type ForgotPasswordModalProps = {
  email: string;
};
export const ForgotPasswordModal = ({
  email: initialEmail,
}: ForgotPasswordModalProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(initialEmail);

  return (
    <>
      <Typography
        className={classes.forgotPassword}
        onClick={() => {
          setOpen(true);
          setEmail(initialEmail);
        }}
      >
        Forgot password
      </Typography>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Modal email={email} setEmail={setEmail} setOpen={setOpen} />
      </Dialog>
    </>
  );
};
