import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { useUser } from "../../redux/selectors";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Loading } from "../fetchers/Loading";
import { Flex } from "../Flex";
import _ from "lodash";
import { VisibilityOff, Visibility } from "@mui/icons-material";

const initialForm = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

type UpdatePasswordForm = typeof initialForm;

const PasswordInput = ({
  label,
  name,
}: {
  label: string;
  name: keyof UpdatePasswordForm;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const params = useFormikContext<UpdatePasswordForm>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      label={label}
      value={params.values[name]}
      onChange={(e) => params.setFieldValue(name, e.target.value)}
      helperText={params.touched[name] && params.errors[name]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={
                showPassword ? "hide the password" : "display the password"
              }
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? "text" : "password"}
    />
  );
};

export const UpdatePasswordModal = () => {
  const user = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  if (!user || !user.email || user?.providerData[0]?.providerId !== "password")
    return null;

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} variant="contained">
        Update Password
      </Button>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Update Password</DialogTitle>
        {/* Form fields go here */}
        <DialogContent>
          <Formik
            initialValues={initialForm}
            onSubmit={async ({ oldPassword, newPassword }) => {
              setIsLoading(true);
              setError("");
              try {
                const credential = EmailAuthProvider.credential(
                  user.email as string,
                  oldPassword
                );
                await reauthenticateWithCredential(user, credential);
                await updatePassword(user, newPassword);
                setIsModalOpen(false);
              } catch (e) {
                if (_.get(e, "code") === "auth/wrong-password") {
                  setError("Incorrect Password");
                } else {
                  setError(JSON.stringify(e));
                }
              }
              setIsLoading(false);
            }}
            validationSchema={Yup.object({
              oldPassword: Yup.string().required("Required"),
              newPassword: Yup.string().required("Required"),
              confirmPassword: Yup.string()
                .required("Required")
                .oneOf([Yup.ref("newPassword")], "Passwords must match"),
            })}
          >
            {(params) => (
              <Flex flexDirection="column" rowGap="16px" pt="8px">
                <PasswordInput label="Old Password" name="oldPassword" />
                <PasswordInput label="New Password" name="newPassword" />
                <PasswordInput
                  label="Confirm New Password"
                  name="confirmPassword"
                />
                <Button
                  onClick={params.submitForm}
                  disabled={params.isSubmitting}
                  variant="contained"
                >
                  Update Password
                </Button>
              </Flex>
            )}
          </Formik>
          {error && <Typography color="error">{error}</Typography>}
          {isLoading && <Loading />}
        </DialogContent>
      </Dialog>
    </>
  );
};
