import { createTheme, PaletteMode, ThemeProvider } from "@mui/material";
import { amber, blue } from "@mui/material/colors";
import { createContext, PropsWithChildren, useMemo } from "react";
import { useUserSettingsValue } from "./localStorage";

export const ThemeModeContext = createContext({
  setThemeMode: (mode: PaletteMode) => {},
});

export const ThemeModeProvider = ({ children }: PropsWithChildren) => {
  const { themeMode, setSettingsValue } = useUserSettingsValue("themeMode");
  const themeModeValue = useMemo(
    () => ({
      setThemeMode: (mode: PaletteMode) => {
        setSettingsValue("themeMode", mode);
      },
    }),
    [setSettingsValue]
  );
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: {
            main: blue[500],
          },
          secondary: {
            main: amber[500],
          },
          primaryText: {
            main: blue[900],
          },
          tonalOffset: {
            light: 0.9,
            dark: 0.2,
          },
        },
      }),
    [themeMode]
  );
  return (
    <ThemeModeContext.Provider value={themeModeValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeModeContext.Provider>
  );
};
